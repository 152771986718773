import {action, observable} from 'mobx/lib/mobx';
import {singleton} from '../../decorators/singleton';
import {ProfileService} from './service';

@singleton
export class ProfileStore {
  @observable
  curUser = null;

  @observable
  initial = false;

  @observable
  loader = false;

  @observable
  isLogged = false;

  @observable
  resetPasswordResult = false;

  @observable
  licensesPlans = [];

  @action
  async actualizeAuthState(callback) {
    this.curUser = await ProfileService.actualizeAuthState();
    if(!this.curUser){
      callback();
    } else {
      this.initial = true;
      this.isLogged = true;
    }
  }

  @action
  toggleInitial(bool) {
    this.initial = bool;
  }

  @action
  async updateDataProfileAction(payload) {
    await ProfileService.updateDataProfile(payload);
    this.actualizeAuthState();
  }

  @action
  async getExtend(payload) {
    this.setLoader(true);
    const result = await ProfileService.extendSubscription(payload);
    this.setLoader(false);
    window.location.replace(result)
  }

  @action
  async getRenew(payload) {
    this.setLoader(true);
    const result = await ProfileService.renewSubscription(payload);
    this.setLoader(false);
    window.location.replace(result)
  }

  @action
  async getBulkPurchase(payload) {
    this.setLoader(true);
    const result = await ProfileService.bulkPurchase(payload);
    this.setLoader(false);
    window.location.replace(result)
  }

  @action
  async getPurchase(payload) {
    this.setLoader(true);
    const result = await ProfileService.Purchase(payload);
    this.setLoader(false);
    window.location.replace(result)
  }

  @action
  async changePassword(payload) {
    const resultPass = await  ProfileService.changePassword(payload);
    if (resultPass) {
      this.setLoginData(false)
    }
  }

  @action
  async resetNewPassword(payload) {
    await  ProfileService.resetPassword(payload);
  }

  @action
  setLoader(data) {
    this.loader = data;
  }
  
  @action
  async getLogin(payload, callback) {
    const authorize = await ProfileService.authorization(payload);
    this.curUser = null;
    this.isLogged = false;

    if (authorize) {
      const currentUser = await ProfileService.actualizeAuthState();
      if (currentUser) {
        this.curUser = currentUser;
        this.isLogged = true;
        callback();
      }
    }

    this.initial = true;
  }

  @action
  async getResetPassword(payload) {
    const result = await ProfileService.forgotPass(payload);
    this.setResetPasswordConfirm(result);
  }

  @action
  async getInfoLicenses() {
    const result = await ProfileService.getInfoByLicenses();
    this.setInfoLicenses(result)
  }

  @action
  setInfoLicenses(data) {
    this.licensesPlans = data;
  }

  @action
  async setResetPasswordConfirm(data) {
    this.resetPasswordResult = data;
  }

  @action
  async getLogoutAction() {
    await ProfileService.getLogout();
    this.curUser = null;
    this.isLogged = false;
  }

  @action
  setLoginData(dataLogin) {
    if (!dataLogin) {
      this.curUser = null;
      this.initial = true;
    }
    this.isLogged = dataLogin;
  }
}

export default new ProfileStore();

import React, {Component} from 'react';
import Header from './components/Layout/Header'
import {ToastContainer, Zoom} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {inject, observer} from 'mobx-react';
import profileStore from './entities/Profile/store';
import TabsMenu from './components/Layout/tabsMenu';
import './App.scss';
import {AppRoutes, privateRoutes} from './routes';
import {ProfileService} from './entities/Profile/service';

@inject((stores) => ({
  routerStore: stores.routing,
  profileStore,
}))

@observer
class App extends Component {
  componentDidMount() {
    const isPrivate = privateRoutes.includes(this.props.routerStore.location.pathname);
    const isConfirmEmail = this.props.routerStore.location.pathname === '/confirm-email';
    this.props.profileStore.actualizeAuthState(()=>{
      isPrivate && this.redirectTo('/login');
      isConfirmEmail ? this.sendConfirmationToken() : this.props.profileStore.toggleInitial(true);
    });
  }

  redirectTo = (path) => {
    this.props.routerStore.history.push(path)
  };

  async sendConfirmationToken() {
    let result = await ProfileService.confirmEmail(window.location.search.split('=')[1]);
    if(!result){
      this.redirectTo('/token-expired');
    }
    this.props.profileStore.toggleInitial(true);
  }

  handleLoginForm = (type) => {
    type === 'login' ? this.setState({triggerForm: false}) : this.setState({triggerForm: true})
  };

  render() {
    if(!this.props.profileStore.initial){
      return <div>Loading....</div>
    }

    return (
      <>
        <Header showLoginForm={this.handleLoginForm} />
        <div className="pmd-content inner-page">
          <div className="container">
            <div className="row">
              <TabsMenu />
              <AppRoutes />
            </div>
          </div>
          <ToastContainer
            position={'top-center'}
            autoClose={5000}
            pauseOnHover
            transition={Zoom}
          />
        </div>
      </>
    )
  }
}

export default App;

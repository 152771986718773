import React from 'react';
import {Route, Switch} from 'react-router';
import {LoadableLoginPage} from './components/Pages/LoginPage/loadable';
import {LoadableRegisterPage} from './components/Pages/registerPage/loadable';
import {LoadableConfirmationPage, LoadableTokenExpired} from './components/Pages/ConfirmationPage/loadable';
import NotFoundMain from './components/Pages/noMatch/NoMatch';
import {LoadableEditProfile} from "./components/Pages/EditProfile/loadable";
import {LoadableMyLicenses} from "./components/Pages/MyLicenses/loadable";
import {LoadableByLicenses} from "./components/Pages/ByLicenses/loadable";
import {LoadableChangePassword} from "./components/Pages/ChangePassword/loadable";
import {LoadableAssessments, LoadableAddNewAssessment} from './components/Pages/Assessments/loadable';
import {LoadableUserData} from './components/Pages/UserData/loadable';
import {LoadableStudentPerformance} from './components/Pages/StudentPerformance/loadable';
import {LoadableDeleteProfile} from "./components/Pages/DeleteProfile/loadable";

export const privateRoutes = [
  '/edit-profile',
  '/subscriptions',
  '/my-subscriptions',
  '/change-password',
  '/assessments',
  '/add-new-assessment',
  '/duplicate-assessment',
  '/edit-assessment',
  '/user-data',
  '/student-performance',
  '/delete-profile',
  '/',
];

export const publicRoutes = [
  '/login',
  '/register',
  '/reset-password',
  '/confirm-email',
  '/token-expired',
];


export const AppRoutes = React.memo(() => {
  return (
    <Switch>
      <Route path="/login" component={LoadableLoginPage}/>
      <Route path="/register" component={LoadableRegisterPage}/>
      <Route path="/reset-password" component={LoadableLoginPage}/>
      <Route path="/confirm-email" component={LoadableConfirmationPage} />
      <Route path="/token-expired" component={LoadableTokenExpired} />

      <Route path="/" component={LoadableUserData} exact/>
      <Route path="/user-data" component={LoadableUserData}/>
      <Route path="/edit-profile" component={LoadableEditProfile}/>
      <Route path="/my-subscriptions" component={LoadableMyLicenses}/>
      <Route path="/subscriptions" component={LoadableByLicenses}/>
      <Route path="/change-password" component={LoadableChangePassword}/>
      <Route path="/assessments" component={LoadableAssessments}/>
      <Route path="/add-new-assessment" component={LoadableAddNewAssessment}/>
      <Route path="/duplicate-assessment/:id" component={LoadableAddNewAssessment}/>
      <Route path="/edit-assessment/:id" component={LoadableAddNewAssessment}/>
      <Route path="/student-performance" component={LoadableStudentPerformance}/>
      <Route path="/delete-profile" component={LoadableDeleteProfile}/>

      <Route path="*" component={NotFoundMain} />
    </Switch>
  );
});
import React, {Component} from 'react';
import {NavLink, Link} from 'react-router-dom';
import {privateRoutes, publicRoutes} from '../../routes';
import {inject, observer} from 'mobx-react';

@inject((stores) => ({
  routerStore: stores.routing
}))
@observer
class TabsMenu extends Component {
  verificationPath = () => {
    let isPrivate = false;
    if (!publicRoutes.includes(this.props.routerStore.location.pathname)) {
      privateRoutes.forEach(item =>{
        if(this.props.routerStore.location.pathname.indexOf(item) >= -1) {
          isPrivate = true;
        }
      })
    }
    return isPrivate;
  };

  render() {
    if(!this.verificationPath()){return null}
    return (
      <div className="sidebar col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <ul className="sidebar-menu">
          <li className="red">
            <NavLink to="/assessments">Assessments</NavLink>
          </li>
          <li className="red">
            <Link to="/student-performance">Student Performance</Link>
          </li>
          <li className="blue">
            <NavLink to="/user-data">User Data</NavLink>
          </li>
        </ul>
        <ul className="sidebar-menu" style={{marginTop: 20}}>
          <li>
            <NavLink to="/my-subscriptions">My Account</NavLink>
          </li>
          <li>
            <NavLink to="/subscriptions">Buy Licenses & Profiles</NavLink>
          </li>          
        </ul>
      </div>
    )
  }
}
export default TabsMenu;

import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import profileStore from "src/entities/Profile/store";
import { NavLink } from "react-router-dom";
import EBLogo from '../../images/EBLogo.png'

@inject((stores) => ({
  routerStore: stores.routing,
  profileStore
}))

@observer
class Header extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  state = {
    showDropdown: false,
    menuIsOpen: false,
    activeMenu: "",
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.showDropdown) {
      this.setState({ showDropdown: false })
    }
  }

  handlerRegisterForm = () => {
    this.props.routerStore.history.push('/login')
  };

  handleDropDown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }))
  };

  handlerClickDropDown = (item) => () => {
    if (item.target.localName === 'span' || item.target.localName === 'div') {
      this.setState({ showDropdown: false })
    }
  };

  logout = () => {
    this.props.routerStore.history.push('/login');
    this.props.profileStore.getLogoutAction();
    this.setState({ showDropdown: false, menuIsOpen: false });
  };

  toggleMenu = () => {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen,
    });
  };

  menuWorker = (title) => () => {
    if (title !== this.state.activeMenu) {
      this.setState({
        activeMenu: title,
      });
    } else {
      this.setState({
        activeMenu: "",
      });
    }
  }

  openMenuWorker = (title) => () => {
    if (title !== this.state.activeMenu) {
      this.setState({
        activeMenu: title,
      })
    }
  }

  closeMenuWorker = () => () => {
    this.setState({
      activeMenu: "",
    })
  }

  closeBarAndRedirectToPage = () => {

  }

  renderUserBox = () => {
    return (
      <div className="my-account dropdown-toggle media" onClick={() => this.handleDropDown()}>
        <div className="media-left">
          <svg
            x="0px"
            y="0px"
            width="22.154px"
            height="24px"
            viewBox="553.449 210.5 22.154 24"
            enableBackground="new 553.449 210.5 22.154 24"
          >
            <path
              fill="#46addd"
              d="M567.388,223.177c2-1.23,3.293-3.662,3.293-5.908c0-3.2-2.616-6.769-6.154-6.769 c-3.539,0-6.154,3.569-6.154,6.769c0,2.246,1.292,4.677,3.292,5.908c-4.739,1.262-8.216,5.601-8.216,10.708 c0,0.338,0.277,0.615,0.616,0.615h20.923c0.338,0,0.615-0.277,0.615-0.615C575.604,228.777,572.126,224.438,567.388,223.177z M559.604,217.269c0-3.169,2.615-5.538,4.923-5.538s4.923,2.369,4.923,5.538c0,3.169-2.615,5.539-4.923,5.539 S559.604,220.438,559.604,217.269z M554.711,233.27c0.308-5.139,4.615-9.23,9.815-9.23s9.508,4.092,9.815,9.23H554.711z"
            />
          </svg>
        </div>
        <div className="media-body media-middle">
          <span>{this.props.profileStore.curUser.firstName}</span>
        </div>
        <div className="media-right">
          <i className="material-icons middle-arrow">arrow_drop_down</i>
        </div>
      </div>
    )
  };

  takePathAndDisableSideBar = (path) => () => {
    this.props.routerStore.history.push(path)
    this.setState({
      menuIsOpen: false
    })
  }

  render() {
    return (
      <nav className="navbar navbar-fixed-top pmd-navbar">
        <div className="container-fluid nav-bar-container">
          <div className="navbar-header">
            <button
              className="pmd-ripple-effect navbar-toggle pmd-navbar-toggle"
              type="button"
              onClick={this.toggleMenu}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <a
              href="https://exerciseconnection.com/exercise-buddy-app/"
              className="navbar-brand"
            >
              <img
                src={EBLogo}
                alt="ExerciseBuddy - Apps for Autism"
                width="210"
              />
            </a>
            <div className="info-container">
              <span className="header-info">&nbsp; A Product of Exercise Connection</span>
            </div>
          </div>
          <div id="side-bar"
            className={`collapse navbar-collapse pmd-navbar-sidebar pull-right ${this.state.menuIsOpen ? 'pmd-sidebar-open' : ''}`}
          >
            <ul className="nav navbar-nav medium-nav header-flex">
              <li className="li-title">
                <a href="https://exerciseconnection.com/why-exercise/">Why Exercise for Autism?</a>
              </li>
              <ul
                className="nav navbar-nav medium-nav header-column"
                onMouseEnter={this.openMenuWorker("solutions")}
                onMouseLeave={this.closeMenuWorker()}
              >
                <li
                  className="li-title"
                  onClick={this.menuWorker("solutions")}
                >
                  <a href="#">Solutions For ⌄</a>
                </li>
                {this.state.activeMenu === "solutions" &&
                  <ul
                    className="hide-menu"
                  >
                    <li className="li-menu">
                      <a className="a-menu" href="https://exerciseconnection.com/solutions-for-exercise-professionals/">
                        Exercise Professionals
                      </a>
                    </li>
                    <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/solutions-for-educators-and-therapists/">Educators & Therapists</a></li>
                    <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/solutions-for-parents/">Parents</a></li>
                    <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/solutions-for-schools-clinics/">Schools</a></li>
                  </ul>
                }
              </ul>
              <ul
                onMouseEnter={this.openMenuWorker("products")}
                onMouseLeave={this.closeMenuWorker()}
                className="nav navbar-nav medium-nav header-column"
              >
                <li
                  className="li-title"
                  onClick={this.menuWorker("products")}
                ><a href="#">Products ⌄</a></li>
                {this.state.activeMenu === "products" && <ul className="hide-menu">
                  <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/exercise-buddy-app/">Exercise Buddy App</a></li>
                  <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/visual-exercise-system/">Visual Exercise System</a></li>
                  <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/autism-exercise-specialist-certificate/">Autism Exercise Specialist Certificate</a></li>
                </ul>}
              </ul>
              <ul
                onMouseEnter={this.openMenuWorker("about")}
                onMouseLeave={this.closeMenuWorker()}
                className="nav navbar-nav medium-nav header-column"
              >
                <li
                  className="li-title"
                  onClick={this.menuWorker("about")}
                ><a href="#">About Us ⌄</a></li>
                {this.state.activeMenu === "about" && <ul className="hide-menu">
                  <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/our-story/">Exercise Connection Story</a></li>
                  <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/meet-the-team/">Meet the Team</a></li>
                  <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/innovation/">Innovation</a></li>
                  <li className="li-menu"><a className="a-menu" href="https://exerciseconnection.com/contact/">Contact</a></li>
                </ul>}
              </ul>
              {this.props.profileStore.curUser &&
                <>
                  <li className="visible-xs"><a onClick={this.takePathAndDisableSideBar('/edit-profile')}>Edit Profile</a></li>
                  <li className="visible-xs"><a onClick={this.takePathAndDisableSideBar('/my-subscriptions')}>My Account</a></li>
                  <li className="visible-xs"><a onClick={this.takePathAndDisableSideBar('/subscriptions')}>Buy Licenses & Profiles</a></li>
                  <li className="visible-xs"><a onClick={this.takePathAndDisableSideBar('/change-password')}>Change Password</a></li>
                  <li className="visible-xs"><a onClick={this.takePathAndDisableSideBar('/assessments')}>Assessments</a></li>
                  <li className="visible-xs"><a onClick={this.takePathAndDisableSideBar('/student-performance')}>Student Performance</a></li>
                  <li className="visible-xs"><a onClick={this.takePathAndDisableSideBar('/user-data')}>User Data</a></li>
                  <li className="visible-xs"><a onClick={this.logout}>Sign Out</a></li>
                </>
              }
              {!this.props.profileStore.curUser &&
                <li>
                  <a
                    className="sign-in handle_login_redirect_btn last-li"
                    onClick={this.handlerRegisterForm}
                  >Sign In</a>
                </li>}
            </ul>
            <ul className="navbar-nav navbar-right hidden-xs nav-end">
              {this.props.profileStore.curUser && this.renderUserBox()}
              <div
                className="dropdown-menu-header"
                onClick={(event) => this.handlerClickDropDown(event)}
                style={{ display: this.state.showDropdown ? 'block' : 'none' }}
                ref={this.setWrapperRef}
              >
                <div><NavLink to="/edit-profile">Edit Profile</NavLink></div>
                <div><NavLink to="/my-subscriptions">My Account</NavLink></div>
                <div><NavLink to="/subscriptions">Buy Licenses & Profiles</NavLink></div>
                <div><NavLink to="/change-password">Change Password</NavLink></div>
                <div><a onClick={this.logout}>Sign Out</a></div>
              </div>
            </ul>
          </div>
        </div>
        <div
          className={`pmd-sidebar-overlay ${this.state.menuIsOpen ? 'pmd-sidebar-overlay-active' : ''}`}
          onClick={this.toggleMenu}
        />
      </nav>
    )
  }
}

export default Header;

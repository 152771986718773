import createStyles from '@material-ui/core/styles/createStyles';


export const styles = () => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // width: '100%',
    // height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: '#fff',
    zIndex: 9999
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  contentPrimary: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }
});


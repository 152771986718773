import {gql} from "apollo-boost";

export const ACTUALIZE_AUTH_STATE = gql`query {
  currentUser {
    firstName
    lastName
    email
    id
    isSubscribedToNewsletter
    trackingActivity
    hasPaidSubscriptions
    role {
      name
    }
    students(filter: {isActive: {eq: true}}){
        firstName
        lastName
        id
    }
    category {
      id
      name
    }
    region {
      id
      name
      country {
        id
        name
      }
    }
    userActiveTrialSubscription {
      subscription{
        name        
        displayName
        id
      }
      licensesNumber
      expirationDate
    }
    userSubscriptions(filter: {isActive: {eq: true}}) {
      id
      expirationDate
      licensesNumber
      subscription {
        name
        displayName
      }
      user {
        id
        email
        createdAt
        forcePasswordReset
      }
      payment {
        method
      }
    }
    userActivities {
      feature
      spentTime
      ratio
    }
    averageActivityTime{
      averagePerDay
      averagePerWeek
    }
    studentActivities (filter: {isActive: {eq: true}}){
      firstName
      averagePerDay
      averagePerWeek
    }
    payments(filter: { isConfirmed: { eq: true } }) {
      id
      userSubscriptions(filter: { isActive: { eq: true } }) {
        isActive
        expirationDate
        id
        licensesNumber
        user {
          forcePasswordReset
          id
          firstName
          lastName
          email
        }
        subscription {
          name
          displayName
          id
        }
      }
    }
    device {
      id
      name
    }
    zipCode
  }
}
`;

export const UPDATE_PROFILE = gql`
    mutation updateUser(
        $id: IntID!,
        $firstName: HumanName,
        $lastName: HumanName,
        $zipCode: ZipCode,
        $regionId: IntID,
        $categoryId: IntID,
        $isActive: Boolean,
        $isSubscribedToNewsletter: Boolean,
        $trackingActivity: Boolean
    ) {
        updateUser(
         id:  $id,
         firstName:  $firstName,
         lastName:  $lastName,
         zipCode:  $zipCode,
         regionId:  $regionId,
         categoryId:  $categoryId,
         isActive:  $isActive,
         isSubscribedToNewsletter:  $isSubscribedToNewsletter,
         trackingActivity: $trackingActivity
        ){
            firstName
        }
    }
`;

export const EXTEND_SUBSCRIPTION = gql`
    mutation extendSubscription(
        $userSubscriptionId: IntID!,
        $autoRenew: Boolean
    ) {
        extendSubscription(
            userSubscriptionId: $userSubscriptionId,
            autoRenew: $autoRenew
        )
    }
`;

export const RENEW_SUBSCRIPTION = gql`
    mutation renewSubscription(
        $userSubscriptionId: IntID!,
        $studentsToReactivate: [IntID!],
        $autoRenew: Boolean
    ) {
        renewSubscription(
            userSubscriptionId: $userSubscriptionId,
            studentsToReactivate: $studentsToReactivate, 
            autoRenew: $autoRenew
        )
    }
`;

export const INFO_BY_LICENSES = gql `
  query {
      allEbSubscriptions(
        filter: { OR: [{ AND: { price: { ne: 0 }, name: { ne: "cheque" } } }] }
      ) {
        displayName
        price
        id
        name
      }
}
`;

export const BULK_PURCHASE_SUBSCRIPTION = gql`
    mutation bulkPurchaseSubscriptions(
        $subscriptionId: PositiveInt!,
        $purchaseDetails: [PurchaseDetailsInput!]!
        $autoRenew: Boolean
    ) {
        bulkPurchaseSubscriptions(
            subscriptionId: $subscriptionId,
            purchaseDetails: $purchaseDetails,
            autoRenew: $autoRenew
        )
    }
`;

export const PURCHASE_SUBSCRIPTION = gql`
    mutation purchaseSubscription(
        $subscriptionId: PositiveInt!,
        $studentsToReactivate: [IntID!]
        $autoRenew: Boolean,
        $licensesNumber: PositiveInt!
    ) {
        purchaseSubscription(
            subscriptionId: $subscriptionId,
            studentsToReactivate: $studentsToReactivate,
            autoRenew: $autoRenew,
            licensesNumber: $licensesNumber
        )
    }
`;

export const GET_ALL_USER_ASSESSMENTS = gql`
  query {
    allAssessments{
      id
      name
      isActive
      exercise{
        category{
        color
        }
        picture{
          key
        }
      }
    }
  }
`;

export const GET_USER_EXERCISES = gql`
  query($offset: PaginationOffset){
    allExerciseCategories(offset: $offset, filter: {isActive: {eq: true}}) {
      isActive
      name
      id
      exercises(filter: {isActive: {eq: true}}) {
        category{
          color
          }
        name
        id
        user{
          id
        }
        picture{
          key
        }
      }
    }
  }
`;

export const CREATE_NEW_ASSESSMENT = gql`
  mutation createAssessment(
        $userId: IntID!,
        $name: EscapedString!,
        $description: LongEscapedString,
        $type: AssessmentType!,
        $exerciseId: IntID!
        $scoreType: AssessmentScoreType!
        $time: ShortInterval
        $trialsNumber: PositiveInt
        $scores: [AssessmentScoreInput]!
        $skills: [AssessmentSkillInput]!
    ) {
      createAssessment(
          userId: $userId,
          name: $name,
          description: $description,
          type: $type,
          exerciseId: $exerciseId,
          scoreType: $scoreType,
          time: $time,
          trialsNumber: $trialsNumber,
          scores: $scores,
          skills: $skills,
        )
        {
          id
        }
    }
`;

export const UPDATE_ASSESSMENT = gql`
  mutation updateAssessment(
        $id: IntID!,
        $name: EscapedString,
        $description: LongEscapedString,
        $type: AssessmentType,
        $exerciseId: IntID
        $scoreType: AssessmentScoreType
        $time: ShortInterval
        $trialsNumber: PositiveInt
        $scores: [AssessmentScoreInput!]
        $skills: [AssessmentSkillInput!]
    ) {
      updateAssessment(
          id: $id,
          name: $name,
          description: $description,
          type: $type,
          exerciseId: $exerciseId,
          scoreType: $scoreType,
          time: $time,
          trialsNumber: $trialsNumber,
          scores: $scores,
          skills: $skills,
        )
        {
          id
        }
    }
`;

export const DELETE_ASSESSMENT = gql`
  mutation updateAssessment(
    $id: IntID!,
    $isActive: Boolean,
  ) {
    updateAssessment(
      id:$id,
      isActive:$isActive
    ) {
      id
      isActive
    }
  }
`;

export const DELETE_ASSESSMENT_HISTORY = gql`
  mutation updateAssessmentHistory(
    $id: IntID!,
    $isActive: Boolean,
  ) {
    updateAssessmentHistory(
      id:$id,
      isActive:$isActive
    ) {
      id
      isActive
    }
  }
`;

export const GET_STUDENTS_ASSESSMENTS = gql`
  query($offset: PaginationOffset){
    allStudents(offset: $offset, filter: {isActive: {eq: true}}){
      firstName
      lastName
      id
      assessments{
        id
      }
    }
  }
`;

export const ADD_ASSESSMENT_TO_STUDENTS = gql`
  mutation assignAssessmentToStudents(
    $id: IntID!,
    $students:[IntID!]!
  ) {
 		assignAssessmentToStudents(
       id: $id,
       students: $students
    ) {
      id
    }
  }
`;

export const ADD_ASSESSMENT_TO_ACCOUNTS = gql`
  mutation assignAssessmentToUsers(
    $id: IntID!,
    $users:[IntID!]!
  ) {
    assignAssessmentToUsers(
       id: $id,
       users: $users
    ) {
      id
    }
  }
`;

export const DUPLICATE_ASSESSMENT = gql`
  query assessment($id: IntID!) {
    assessment(id: $id) {
      id
      name
      description
      isActive
      scoreType
      scores {
        score
        description
      }
      skills {
        name
      }
      time
      trialsNumber
      type
      exercise {
        id
        name
        category {
          color
        }
        picture {
          key
        }
      }
    }
  }
`;

export const GET_USER_STUDENTS = gql`
  query allStudents{
    allStudents (filter: {isActive: {eq: true}}){
      id
      firstName
      lastName
    }
  }
`;

export const GET_LATEST_STUDENT_ASSESSMENT_HISTORIES = gql`
  query allLatestAssessmentHistories($id: IntID!, $offset: PaginationOffset){
    allLatestAssessmentHistories(filter:  {AND: [{isActive: {eq: true} }, {studentId:{eq: $id}}]}, offset: $offset){
      id
      performanceDate
      name
      description
      scoreType
      isActive
      assessment{
        id
        scores{
          score
          description
        }
      }
      advancements{        
        skill
        score
      }
      achievementLevel
      note
      student{
        id
      }    
    }
  }
`;

export const GET_SAME_STUDENT_ASSESSMENT_HISTORIES = gql`
  query allAssessmentHistories($id: IntID!, $offset: PaginationOffset, $name: EscapedString!){
    allAssessmentHistories(filter: {AND: [{name: {eq: $name} }, {isActive: {eq: true} }, {studentId:{eq: $id}}]}, offset: $offset){
      id
      performanceDate
      name
      description
      scoreType
      isActive
      assessment{
        id
        scores{
          score
          description
        }        
      }
      advancements{        
        skill
        score
      }
      achievementLevel
      note
      student{
        id
      }    
    }
  }
`;

export const UPDATE_ASSESSMENT_HISTORY = gql`
  mutation(
    $id: IntID!
    $advancements: [AssessmentHistoryAdvancementInput]
    $achievementLevel: AchievementLevel
    $note: LongEscapedString
  ) {
    updateAssessmentHistory (
      id: $id
      advancements: $advancements
      achievementLevel: $achievementLevel
      note: $note
    ) {
      id     
    }
  }
`;

import * as React from 'react';
import Loadable from 'react-loadable';
export const LoadableMyLicenses = Loadable({
    loader: () =>
        import(
            'src/components/Pages/MyLicenses/myLicenses').then(
            module => module.default,
        ),
    loading: () => <div style={ {minHeight: '100vh', textAlign: 'center'} }>Loading...</div>,
});

import * as React from 'react';
import Loadable from 'react-loadable';

export const LoadableConfirmationPage = Loadable({
    loader: () =>
      import('src/components/Pages/ConfirmationPage/confirmationPage').then(module => module.default),
      loading: () => <div style={ {minHeight: '100vh', textAlign: 'center'} }>Loading...</div>
});

export const LoadableTokenExpired = Loadable({
    loader: () =>
      import('src/components/Pages/ConfirmationPage/tokenExpired').then(module => module.default),
      loading: () => <div style={ {minHeight: '100vh', textAlign: 'center'} }>Loading...</div>
});

import {api, client} from '../../services/api';
import {
    ACTUALIZE_AUTH_STATE,
    EXTEND_SUBSCRIPTION,
    RENEW_SUBSCRIPTION,
    UPDATE_PROFILE,
    INFO_BY_LICENSES,
    BULK_PURCHASE_SUBSCRIPTION,
    PURCHASE_SUBSCRIPTION,
    GET_ALL_USER_ASSESSMENTS,
    GET_USER_EXERCISES,
    CREATE_NEW_ASSESSMENT,
    DELETE_ASSESSMENT,
    GET_STUDENTS_ASSESSMENTS,
    ADD_ASSESSMENT_TO_STUDENTS,
    DUPLICATE_ASSESSMENT,
    UPDATE_ASSESSMENT,
    GET_USER_STUDENTS,
    GET_LATEST_STUDENT_ASSESSMENT_HISTORIES,
    GET_SAME_STUDENT_ASSESSMENT_HISTORIES,
    DELETE_ASSESSMENT_HISTORY,
    UPDATE_ASSESSMENT_HISTORY,
    ADD_ASSESSMENT_TO_ACCOUNTS, DELETE_PROFILE
} from './queries';
import {toast} from "react-toastify";

export class ProfileService {
  static async actualizeAuthState() {
    let data = null;
    try {
      const response = await client.query({
        query: ACTUALIZE_AUTH_STATE,
        variables: {},
      });

      if (response.errors) {
        console.error('ProfileService->actualizeAuthState->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data && response.data.currentUser) {
        data = response.data.currentUser;
        if (data.role.name !== 'customer') {
          toast.error('You must have a \'customer\' role. Please try to log in with a different account');
          return null;
        }
      }
      return data;
    } catch (error) {
      console.error('ProfileService->actualizeAuthState->error', error);
      return null;
    }
  }

  static async updateDataProfile(payload) {
    let data;
    try {
      const response = await client.mutate({
        mutation: UPDATE_PROFILE,
        variables: payload,
      });

      if (response.errors) {
        console.error('ProfileService->actualizeAuthState->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data && response.data.updateUser) {
        data = response.data.updateUser;
        toast.success('Profile updated')
      }
      return data;
    } catch (error) {
      console.error('ProfileService->actualizeAuthState->error', error);
      return null;
    }
  }

  static async extendSubscription(payload) {
    let data;

    const variables = {
      userSubscriptionId: payload,
      autoRenew: payload[1] !== null
    };
    try {
      const response = await client.mutate({
        mutation: EXTEND_SUBSCRIPTION,
        variables: variables,
      });
      if (response.errors) {
        console.error('ProfileService->EXTEND_USER->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data) {
        data = response.data.extendSubscription;
      }
      // console.info('ProfileService->actualizeAuthState->data', data);
      return data;
    } catch (error) {
      console.error('ProfileService->EXTEND_USER->error', error);
      // toast.error(error.message)
      return null;
    }
  }

  static async renewSubscription(payload) {
    let data;
    const variables = {
      userSubscriptionId: payload.id,
      studentsToReactivate: payload.selected,
      autoRenew: payload.auto
    };
    try {
      const response = await client.mutate({
        mutation: RENEW_SUBSCRIPTION,
        variables: variables,
      });
      if (response.errors) {

        console.error('ProfileService->RENEW_USER->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data) {

        data = response.data.renewSubscription;
      }
      // console.info('ProfileService->actualizeAuthState->data', data);
      return data;
    } catch (error) {
      console.error('ProfileService->EXTEND_USER->error', error);
      // toast.error(error.message)
      return null;
    }
  }

  static async bulkPurchase(payload) {
    let data;
    const variables = {
      subscriptionId: parseInt(payload.choosePlan.id),
      purchaseDetails: payload.purchasedFor,
      autoRenew: payload.auto
    };
    try {
      const response = await client.mutate({
        mutation: BULK_PURCHASE_SUBSCRIPTION,
        variables: variables,
      });
      if (response.errors) {
        console.error('ProfileService->BULK_PURCHASE->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data) {
        data = response.data.bulkPurchaseSubscriptions;
      }
      return data;
    } catch (error) {
      console.error('ProfileService->EXTEND_USER->error', error);
      return null;
    }
  }

  static async Purchase(payload) {
    let data;
    const variables = {
      subscriptionId: parseInt(payload.choosePlan.id),
      studentsToReactivate: payload.studentsToReactivate,
      licensesNumber: payload.licensesNumber,
      autoRenew: payload.autoRenew
    };
    try {
      const response = await client.mutate({
        mutation: PURCHASE_SUBSCRIPTION,
        variables: variables,
      });
      if (response.errors) {
        console.error('ProfileService->PURCHASE->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data) {
        data = response.data.purchaseSubscription;
      }
      // console.info('ProfileService->actualizeAuthState->data', data);
      return data;
    } catch (error) {
      console.error('ProfileService->EXTEND_USER->error', error);
      // toast.error(error.message)
      return null;
    }
  }

  static getStudentsAssessments() {
    return new Promise((resolve) => {
      let allStudentsAssess = [];

      const variable = {
          offset: 0
      };

      const iteration = () => {
        client.query({
            query: GET_STUDENTS_ASSESSMENTS,
            variables: variable
        }).then(response => {
          if (response.data) {
            allStudentsAssess = [...allStudentsAssess, ...response.data.allStudents];
              if (response.data.allStudents.length < 100) {
                  resolve(allStudentsAssess);
              } else {
                  variable.offset += 100;
                  iteration();
              }
          } else {
            // toast.error(error.message);
          }
        })
      };
      iteration();
    });
  }

  static async getUserStudents() {
      try {
          const response = await client.query({
              query: GET_USER_STUDENTS,
          });

          return response.data.allStudents;
      } catch (error) {          
        toast.error(error.message);
        return null; 
      }
  }

  static async deleteAssessment(payload) {
    let data;
    const variables = {
      id: +payload,
      isActive: false
    };
    try {
      const response = await client.mutate({
        mutation: DELETE_ASSESSMENT,
        variables: variables,
      });
      if (response.errors) {
        console.error('ProfileService->Delete Assessment->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data.updateAssessment) {
        data = response.data.updateAssessment;
      }
      return data;
    } catch (error) {
      console.error('ProfileService->Delete assessment->error', error);
      toast.error(error.message);
      return null;
    }
  }

    static async deleteAssessmentHistory(payload) {
        let data;
        const variables = {
            id: +payload,
            isActive: false
        };
        try {
            const response = await client.mutate({
                mutation: DELETE_ASSESSMENT_HISTORY,
                variables: variables,
            });
            if (response.errors) {
                console.error('ProfileService->Delete Assessment history->error', response.data, response.errors);
                throw new Error(
                    response.errors
                        .map(function (err) {
                            return err.message;
                        })
                        .join('. '),
                );
            }
            if (response && response.data.updateAssessmentHistory) {
                data = response.data.updateAssessmentHistory;
            }
            return data;
        } catch (error) {
            console.error('ProfileService->Delete assessment history->error', error);
            toast.error(error.message);
            return null;
        }
    }

    static async updateAssessment(payload, callback) {
        let data;
        const variables = {
            id: payload.assessId,
            name: payload.name,
            description: payload.description,
            type: payload.radioTypePhysicalOther,
            exerciseId: payload.exerciseId,
            scoreType: payload.scoreType,
            time: payload.time,
            trialsNumber: payload.trialsNumber,
            scores: payload.scores,
            skills: payload.skills
        };
        try {
            const response = await client.mutate({
                mutation: UPDATE_ASSESSMENT,
                variables: variables,
            });
            if (response.errors) {
                console.error('ProfileService->Update assessment->error', response.data, response.errors);
                throw new Error(
                    response.errors
                        .map(function (err) {
                            return err.message;
                        })
                        .join('. '),
                );
            }
            if (response && response.data.updateAssessment) {
                data = response.data.updateAssessment;
                toast.success('Assessment updated!');
                callback();
            }
            return data;
        } catch (error) {
          console.log(error)
            console.error('ProfileService->Update assessment->error', error);
            toast.error(error.message.split('GraphQL error:').join(' '))
            return null;
        }
    }

    static async updateAssessmentHistory(payload) {
        let data;
        const variables = {
            id: payload.id,
            note: payload.note,
            achievementLevel: payload.achievementLevel,
            advancements: payload.advancements.map(skill => ({
                skill: skill.skill,
                score: skill.score
            }))
        };
        try {
            const response = await client.mutate({
                mutation: UPDATE_ASSESSMENT_HISTORY,
                variables: variables,
            });
            if (response.errors) {
                console.error('ProfileService->Update assessment->error', response.data, response.errors);
                throw new Error(
                    response.errors
                        .map(function (err) {
                            return err.message;
                        })
                        .join('. '),
                );
            }
            if (response && response.data.updateAssessmentHistory) {
                data = response.data.updateAssessmentHistory;
                toast.success('Assessment history updated!');
            }
            return data;
        } catch (error) {
            console.error('ProfileService->Update assessment->error', error);
            toast.error(error.message);
            return null;
        }
    }

  static async updateStudentsAssessment(payload) {
    let data;
    const variables = {
      id: +payload.id,
      students: payload.students
    };
    try {
      const response = await client.mutate({
        mutation: ADD_ASSESSMENT_TO_STUDENTS,
        variables: variables,
      });
      if (response.errors) {
        console.error('ProfileService->Update Students Assessment->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data.assignAssessmentToStudents) {
        data = response.data.assignAssessmentToStudents;
        toast.success('Student(s) assigned to assessment');
      }
      return data;
    } catch (error) {
      console.error('ProfileService->Update Students Assessment->error', error);
      toast.error(error.message);
      return null;
    }
  
  }
  static async updateUsersAssessment(payload) {
    let data;
    const variables = {
      id: +payload.id,
      users: payload.users
    };
    try {
      const response = await client.mutate({
        mutation: ADD_ASSESSMENT_TO_ACCOUNTS,
        variables: variables,
      });
      if (response.errors) {
        console.error('ProfileService->Update Students Assessment->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data.assignAssessmentToUsers) {
        data = response.data.assignAssessmentToUsers;
        toast.success('User(s) assigned to assessment');
      }
      return data;
    } catch (error) {
      console.error('ProfileService->Update Students Assessment->error', error);
      toast.error("Record already exist");
      return null;
    }
  }

  static async duplicateAssessment(payload) {
    let data;
    const variables = {
      id: +payload
    };
    try {
      const response = await client.query({
        query: DUPLICATE_ASSESSMENT,
        variables: variables,
      });
      if (response.errors) {
        console.error('ProfileService->Duplicate assessment->error', response.data, response.errors);
        throw new Error(
          response.errors.map(function (err) {return err.message}).join('. '),
        );
      }
      if (response && response.data.assessment) {
        data = response.data.assessment;
      }
      return data;
    } catch (error) {
      console.error('ProfileService->Duplicate assessment->error', error);
      toast.error(error.message.split('GraphQL error:').join(' '))
      return null;
    }
  }

  static async createNewAssessment(payload, callback) {
    let data;
    const variables = {
      userId: payload.userId,
      name: payload.name,
      description: payload.description,
      type: payload.radioTypePhysicalOther,
      exerciseId: payload.exerciseId,
      scoreType: payload.scoreType,
      time: payload.time,
      trialsNumber: payload.trialsNumber,
      scores: payload.scores,
      skills: payload.skills
    };
    try {
      const response = await client.mutate({
        mutation: CREATE_NEW_ASSESSMENT,
        variables: variables,
      });
      if (response.errors) {
        console.error('ProfileService->Create new assessment->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          }).join('. '));
      }
      if (response && response.data.createAssessment) {
        data = response.data.createAssessment;
        toast.success('Assessment created!');
        callback();
      }
      return data;
    } catch (error) {
      console.error('ProfileService->Create new assessment->error', error);
      if(error.message === 'GraphQL error: Record already exists'){
        toast.error('Assessment with this name already exists. Rename your assessment.');
      }
      else{
        toast.error(error.message.split('GraphQL error:').join(' '))
      }
      return null;
    }
  }

  static async authorization(payload) {
    const variables = {
      email: payload.email,
      password: payload.password
    };
    try {
      const response = await api.post('sign-in', variables, {withCredentials: true});

      if (response.ok) {
        return true;
      } else {
        if (response.data.error.code === "AUTHENTICATION_FAILED") {

          toast.error('Wrong Login or Password, please try again!');
        } else if (response.data.error.code === "REQUEST_PAYLOAD_VALIDATION_ERROR") {

          toast.error("Oops! Something went wrong. Please reload the page and try again");
        } else {

          toast.error(response.data.error.message);
        }
        return false;
      }

    } catch (error) {

    }
  }

  static async confirmEmail(token) {
    const variables = {
      token: token
    };

    const response = await api.post('/confirm-email', variables);

    if(response.ok) {
      return true;
    } else {
      toast.error(response.data.error.message);
      return false;
    }
  }

  static async forgotPass(payload) {
    const variables = {
      email: payload.email,
    };
    try {
      const response = await api.post('/request-password-reset', variables, {withCredentials: true});
      if (response.ok) {
        toast.success('Reset password mail is sent to your email successfully.')
        return true;
      } else {
        if (response.data.error.code === "AUTHENTICATION_FAILED") {

          toast.error('Wrong Login or Password, please try again!');
        } else if (response.data.error.code === "REQUEST_PAYLOAD_VALIDATION_ERROR") {

          toast.error("Oops! Something went wrong. Please reload the page and try again");
        } else {
          toast.error(response.data.error.message);
        }
        return false;
      }

    } catch (error) {
      console.error(error)
    }
  }

  static async getLogout() {
    try {
      const response = await api.post('sign-out', '', {withCredentials: true});

      if (response.ok) {
        return true;
      } else {
        if (response.data.error.code === "AUTHENTICATION_FAILED") {

          toast.error('Wrong Login or Password, please try again!');
        } else if (response.data.error.code === "REQUEST_PAYLOAD_VALIDATION_ERROR") {

          toast.error("Oops! Something went wrong. Please reload the page and try again");
        } else {

          toast.error(response.data.error.message);
        }
        return false;
      }

    } catch (error) {

    }
  }

  static async getInfoByLicenses() {
    let data = false;
    try {
      const response = await client.query({
        query: INFO_BY_LICENSES
      });
      // console.info('ProfileService->actualizeAuthState->response', response);
      if (response.errors) {
        console.error('ProfileService->infoByLicenses->error', response.data, response.errors);
        throw new Error(
          response.errors
          .map(function (err) {
            return err.message;
          })
          .join('. '),
        );
      }
      if (response && response.data && response.data.allEbSubscriptions) {
        data = response.data.allEbSubscriptions;
      }
      // console.info('ProfileService->actualizeAuthState->data', data);
      return data;
    } catch (error) {
      console.error('ProfileService->infoByLicenses->error', error);
      // toast.error(error.message)
      return null;
    }
  }

  static async getAllUserAssessments() {
    try {
      const response = await client.query({
        query: GET_ALL_USER_ASSESSMENTS
      });

      return response.data.allAssessments;
    } catch (error){
      toast.error(error.message);
      return null;
    }
  }

  static async getAllUserExercises() {
    return new Promise((resolve) => {
      let allExerciseCategories = [];

      const variable = {
        offset: 0
      };

      const iteration = () => {
          client.query({
              query: GET_USER_EXERCISES,
              variables: variable
          }).then(response => {
              if (response.data) {
                  allExerciseCategories = [...allExerciseCategories, ...response.data.allExerciseCategories];
                  if (response.data.allExerciseCategories.length < 100) {
                      resolve(allExerciseCategories);
                  } else {
                      variable.offset += 100;
                      iteration();
                  }
              }
          })
      };
      iteration();
    });
  }

    static async getAllLatestStudentAssessmentHistories(payload) {
        return new Promise((resolve) => {
            let allLatestAssessmentHistories = [];

            const variable = {
                id: payload,
                offset: 0,
            };

            const iteration = () => {
                client.query({
                    query: GET_LATEST_STUDENT_ASSESSMENT_HISTORIES,
                    variables: variable
                }).then(response => {
                    if (response.data) {
                        allLatestAssessmentHistories = [...allLatestAssessmentHistories, ...response.data.allLatestAssessmentHistories];
                        if (response.data.allLatestAssessmentHistories.length < 100) {
                            resolve(allLatestAssessmentHistories);
                        } else {
                            variable.offset += 100;
                            iteration();
                        }
                    }
                })
            };
            iteration();
        });
    }

    static async getAllStudentAssessmentHistories(payload) {
        return new Promise((resolve) => {
            let allAssessmentHistories = [];

            const variable = {
                id: payload.student.id,
                name: payload.name,
                offset: 0
            };

            const iteration = () => {
                client.query({
                    query: GET_SAME_STUDENT_ASSESSMENT_HISTORIES,
                    variables: variable
                }).then(response => {
                    if (response.data) {
                        allAssessmentHistories = [...allAssessmentHistories, ...response.data.allAssessmentHistories];
                        if (response.data.allAssessmentHistories.length < 100) {
                            resolve(allAssessmentHistories);
                        } else {
                            variable.offset += 100;
                            iteration();
                        }
                    }
                })
            };
            iteration();
        });
    }

  static async changePassword(payload) {
    try {
      const response = await api.post('/change-password', payload, {withCredentials: true});
      if (response.ok) {
        toast.success('Your password changed successfully');
        return true;
      } else {
        if (response.data.error.code === "AUTHENTICATION_FAILED") {

          toast.error('Wrong Login or Password, please try again!');
        } else if (response.data.error.code === "REQUEST_PAYLOAD_VALIDATION_ERROR") {

          toast.error("Oops! Something went wrong. Please reload the page and try again");
        } else {
          toast.error(response.data.error.message);
        }
        return false;
      }

    } catch (error) {
      console.error(error)
    }
  }

  static async resetPassword(payload) {

    try {
      const response = await api.post('/reset-password', payload, {withCredentials: true});
      if (response.ok) {
        toast.success('Your password changed successfully');
        return true;
      } else {
        if (response.data.error.code === "AUTHENTICATION_FAILED") {

          toast.error('Wrong Login or Password, please try again!');
        } else if (response.data.error.code === "REQUEST_PAYLOAD_VALIDATION_ERROR") {

          toast.error("Oops! Something went wrong. Please reload the page and try again");
        } else {
          toast.error(response.data.error.message);
        }
        return false;
      }

    } catch (error) {
      console.error(error)
    }
  }
}

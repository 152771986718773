export function singleton(target) {
  // save a reference to the original constructor
  const originalConstructor = target;
  let instance;

  // a utility function to generate instances of a class
  function instantiate(constructor, ...args) {
    return new constructor(...args);
  }

  // the new constructor behaviour
  const newConstructor = function(...args) {
    if (!instance) {
      instance = instantiate(originalConstructor, ...args);
    }
    return instance;
  };

  // copy prototype so instanceof operator still works
  newConstructor.prototype = originalConstructor.prototype;

  // return new constructor (will override original)
  return newConstructor;
}
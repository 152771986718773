/** @format */

import * as React from 'react';
import {styles} from './styles';
import {withStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {inject, observer} from 'mobx-react';
import profileStore from '../../../entities/Profile/store';

@inject((stores) => ({
    profileStore,
}))

@observer
class NotFound extends React.Component {
    redirectToHome = () => {
        if(this.props.profileStore.isLogged){
            this.props.history.push('/edit-profile');
        } else {
            this.props.history.push('/login');
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    <a href="http://register.exercisebuddy.com/users/login">
                        <img src="//static1.squarespace.com/static/5506446ee4b0fe6c8e8ce57f/t/555aa4c2e4b003aeea38e21f/1599904409208/?format=1500w"
                                alt="ExerciseBuddy - Apps for Autism" className="logo"/>
                    </a>

                    <div className={classes.contentPrimary}>

                        <h1 className="title">Page Not Found</h1>
                        <p className="description">The page you are looking for was moved, removed,
                            renamed or might never existed.</p>
                        <div className="section-footer">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={()=>this.redirectToHome()}
                            >
                                Back To Home page
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const NotFoundMain = withStyles(styles)(NotFound);

export default NotFoundMain;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.scss';
import './print.scss';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router} from 'react-router';
import { BrowserRouter } from 'react-router-dom'
import {createBrowserHistory} from "history";

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();
const stores = {
    // Key can be whatever you want
    routing: routingStore,
};
const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(
  <BrowserRouter>
    <Provider {...stores}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

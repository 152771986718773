import {create} from 'apisauce';
import {ApolloClient} from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createHttpLink} from 'apollo-link-http';
import config from './config';
import {onError} from 'apollo-link-error'

const {GRAPHQL_URL, API_URL} = config;

export const api = create({
    baseURL: API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

const errorLink = onError(({networkError, graphQLErrors}) => {
    if (graphQLErrors) {
        graphQLErrors.map(({message, locations, path}) =>
            console.info(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
        );
    }
    if (networkError) console.info(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
    uri: GRAPHQL_URL,
    credentials: 'include',
});

const link = ApolloLink.from([
    errorLink,
    httpLink,
]);

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
        mutate: {
            errorPolicy: 'none',
        }
    },
});


import * as React from 'react';
import Loadable from 'react-loadable';
export const LoadableByLicenses = Loadable({
  loader: () =>
    import(
      'src/components/Pages/ByLicenses/buyLicenses').then(
      module => module.default,
    ),
  loading: () => <div style={ {minHeight: '100vh', textAlign: 'center'} }>Loading...</div>,
});

const PREFIX = "REACT_APP_";
const config = {};

for (const [name, value] of Object.entries(process.env)) {
  if (!name.startsWith(PREFIX)) {
    continue;
  }

  // remove first occurrence
  const nameWithoutPrefix = name.replace(PREFIX, "");
  config[nameWithoutPrefix] = value;
}

export default config;

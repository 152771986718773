import * as React from 'react';
import Loadable from 'react-loadable';

export const LoadableAssessments = Loadable({
    loader: () =>
      import('src/components/Pages/Assessments/assessments').then(module => module.default),
      loading: () => <div style={ {minHeight: '100vh', textAlign: 'center'} }>Loading...</div>
});

export const LoadableAddNewAssessment = Loadable({
    loader: () =>
      import('src/components/Pages/Assessments/addNewAssessment').then(module => module.default),
      loading: () => <div style={ {minHeight: '100vh', textAlign: 'center'} }>Loading...</div>
});
